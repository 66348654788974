<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				static:[],
            }
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.$set(this.$root, 'page', this);

            this.apiGetStatic()
		},
        methods: {
        	async getStatic(id){
	            return (await Gen.apirest('/static-content', {id:id}, ()=>{})).data
	        },
	        async apiGetStatic(){
	            this.static = await this.getStatic(1)
	        },
        },
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">					
					<div class="row  justify-content-center dark">
						<div class="col-md-11">
							<div class="wrap_login">
								<h2>{{ static.title }}</h2>
							</div>
						</div>
						<div class="col-md-11">
							<div class="wrap_term_condition">
								<p v-html="static.description"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear"></div>
	</div>
	</section>
</template>